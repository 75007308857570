
.theme-file {
  margin-left:0px;
  margin-right:0px;
}
.theme-file > button:first-child {
  margin-right: 20px;
}
.theme-file > button:last-child {
  margin-left: 10px;
}
.theme-file .custom-file {
  width:auto;
  flex-grow:1;
}



form[name=site-switch-form] {
  display: flex;
}
form[name=site-switch-form] > div {
  display: flex;
  margin-bottom: 0;
  flex-grow:1;
}
form[name=site-switch-form] > div > label {
  margin-right:10px;
  line-height: 36px;
  margin-bottom: 0px;
}
form[name=site-switch-form] > div > div {
  flex-grow:1;
}
form[name=site-switch-form] > button {
  margin-left:10px;
}

